<template>
	<div>
		<meta
			name="viewport"
			content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
     user-scalable=0"
		/>
		<qrcode-stream
			:key="_uid"
			:track="selected.value"
			:torch="torchActive"
			@init="logErrors"
			@decode="onDecode"
			:camera="camera"
			class="qrReader"
		>
			<!-- 상단 메뉴 -->
			<v-layout :class="$vuetify.breakpoint.xs ? 'qrHeadxs' : 'qrHead'">
				<v-flex class="qrTitle">Commute QR Reader</v-flex>
				<v-btn class="hideBtn" id="hiddenBtn" @click="hides"></v-btn>
				<v-spacer />
				<!-- <v-icon size="3vmax" class="iconBtn" color="white" @click="switchCamera">mdi-camera-flip-outline</v-icon> -->
				<v-icon size="3.5vmax" class="iconBtn" color="white" @click="torchActive = !torchActive" :disabled="torchNotSupported">
					mdi-flash-outline
				</v-icon>
			</v-layout>
			<!-- qr 인증성공 메세지 -->
			<div class="confirmation" justify-center v-show="alertCard1">
				<v-card class="alertCard1" color="yellow"> 출근 되었습니다.</v-card>
			</div>
			<!-- 이미 인증됨 메세지 -->
			<div class="confirmation" justify-center v-show="alertCard2">
				<v-card class="alertCard2" color="error"> 이미 출근 되었습니다.</v-card>
			</div>
			<!-- qr 인증 실패 -->
			<div class="confirmation" justify-center v-show="alertCard3">
				<v-card class="alertCard1" color="yellow"> 퇴근 되었습니다.</v-card>
			</div>
			<!-- 이미 퇴근됨 메세지 -->
			<div class="confirmation" justify-center v-show="alertCard4">
				<v-card class="alertCard2" color="error"> 이미 퇴근 되었습니다.</v-card>
			</div>
			<!-- qr 인증박스 -->
			<v-layout :class="$vuetify.breakpoint.xs ? 'qrMiddlexs' : 'qrMiddle'" align-center justify-center>
				<div :class="nowWidth"></div>
			</v-layout>
			<!-- 촬영 버튼 -->
			<btn :btn="btn" btn_icon="mdi-camera-flip-outline" btn_txt="카메라 전환" @click="switchCamera"></btn>
		</qrcode-stream>
	</div>
</template>

<script>
// import VueQrcode from 'vue-qrcode'
import { btn } from '@/components/index.js'
export default {
	components: {
		btn,
	},
	data() {
		const options = [
			{ text: 'nothing (default)', value: undefined },
			{ text: 'outline', value: this.paintOutline },
			{ text: 'centered text', value: this.paintCenterText },
			{ text: 'bounding box', value: this.paintBoundingBox },
		]

		const selected = options[1]

		return {
			selected,
			options,
			groupCodes: [],
			//카메라 방향
			camera: 'front',
			//카메라 유무 체크
			noRearCamera: false,
			noFrontCamera: false,
			// flash light
			torchActive: false,
			torchNotSupported: false,
			// 찍은 qr 정보
			result: null,
			// 인증완료 카드 model
			alertCard1: false,
			alertCard2: false,
			alertCard3: false,
			alertCard4: false,
			// 촬영 버튼
			btn: {
				block: true,
				dark: true,
				height: '7%',
				color: '#7DC269',
				class: 'qrCapture',
				icon: true,
				iconSize: '24px',
			},

			// info 다이어로그
			qrinfoDialog: { dialog: false, title: 'Test Business Name' },
			// 음성 재생 데이터
			voiceList: [],
			greetingSpeech: new window.SpeechSynthesisUtterance(),
			synth: window.speechSynthesis,
			/// user 이름
			userData: {},
			greetingUser: '',
			greetingUser2: '이미 인증되었습니다',
			greetingUser1: '예약일시를 확인하세요',
			greetingNum: '',
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight,
			groupdata: {},
			/// 상담자 reserve user 데이터
			reservedUser: {},

			// 오늘 대기 번호
			waitNum: '',

			title: '', // (reservedays) 사업지명
			tplCode: '', // tplCode
			messageData: {}, // 알림톡 전송 정보
			originMsg: '',
			voiceSystemsList: {
				StartWork: { voice: '', version: 0 },
				LeaveWork: { voice: '', version: 0 },
				AlreadyStartWork: { voice: '', version: 0 },
				AlreadyLeaveWork: { voice: '', version: 0 },
			},
		}
	},
	created() {
		this.voiceSystems()
	},
	mounted() {
		// 음성 목소리 list 추출
		this.voiceList = this.synth.getVoices()

		this.synth.onvoiceschanged = () => {
			this.voiceList = this.synth.getVoices()
		}
	},
	computed: {
		nowWidth() {
			window.addEventListener('resize', () => {
				this.windowWidth = window.innerWidth
			})
			window.addEventListener('resize', () => {
				this.windowHeight = window.innerHeight
			})
			if (this.windowWidth <= 375) {
				return 'middleSquarexxxs'
			} else if (this.windowWidth <= 375) {
				return 'middleSquarexxs'
			} else if (this.windowWidth <= 425) {
				return 'middleSquarexs'
			} else if (this.windowWidth <= 768) {
				return 'middleSquaresm'
			} else if (this.windowWidth <= 1024) {
				return 'middleSquaremd'
			} else {
				return 'middleSquare'
			}
		},
		nowHeight() {
			window.addEventListener('resize', () => {
				this.windowHeight = window.innerHeight
			})
			return this.windowHeight
		},
	},
	methods: {
		voiceSystems() {
			const data = {
				show: true,
			}
			this.$store
				.dispatch('voiceSystems', data)
				.then(res => {
					res.voiceSystems.forEach(element => {
						if (element.type === 'Start Work') {
							if (this.voiceSystemsList.StartWork.version < element.version) {
								this.voiceSystemsList.StartWork = { voice: element.voice, version: element.version }
							}
						} else if (element.type === 'Leave Work') {
							if (this.voiceSystemsList.LeaveWork.version < element.version) {
								this.voiceSystemsList.LeaveWork = { voice: element.voice, version: element.version }
							}
						} else if (element.type === 'Already Start Work') {
							if (this.voiceSystemsList.AlreadyStartWork.version < element.version) {
								this.voiceSystemsList.AlreadyStartWork = { voice: element.voice, version: element.version }
							}
						} else if (element.type === 'Already Leave Work') {
							if (this.voiceSystemsList.AlreadyLeaveWork.version < element.version) {
								this.voiceSystemsList.AlreadyLeaveWork = { voice: element.voice, version: element.version }
							}
						}
					})
				})
				.catch(err => {
					console.log({ err })
				})
		},
		voice(voice) {
			this.greetingSpeech.text = `${voice}`
			// 한국어 목소리 선택
			let index = this.voiceList.findIndex(e => e.name === 'Google 한국의')
			this.greetingSpeech.voice = this.voiceList[index]

			this.synth.speak(this.greetingSpeech)
		},
		updateUser_NoAuth(users) {
			const data = {
				id: users.id,
				vacationsDays: users.vacationsDays + 1,
			}
			this.$store
				.dispatch('updateUser_NoAuth', data)
				.then(() => {})
				.catch(err => {
					console.log({ err })
				})
		},
		turnOffAlert() {
			setTimeout(() => {
				this.alertModal4.dialog = false
			}, 2000)
		},
		paintOutline(detectedCodes, ctx) {
			for (const detectedCode of detectedCodes) {
				const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

				ctx.strokeStyle = 'yellow'

				ctx.beginPath()
				ctx.moveTo(firstPoint.x, firstPoint.y)
				for (const { x, y } of otherPoints) {
					ctx.lineTo(x, y)
				}
				ctx.lineTo(firstPoint.x, firstPoint.y)
				ctx.closePath()
				ctx.stroke()
			}
		},

		paintBoundingBox(detectedCodes, ctx) {
			for (const detectedCode of detectedCodes) {
				const {
					boundingBox: { x, y, width, height },
				} = detectedCode

				ctx.lineWidth = 2
				ctx.strokeStyle = '#007bff'
				ctx.strokeRect(x, y, width, height)
			}
		},

		paintCenterText(detectedCodes, ctx) {
			for (const detectedCode of detectedCodes) {
				const { boundingBox, rawValue } = detectedCode

				const centerX = boundingBox.x + boundingBox.width / 2
				const centerY = boundingBox.y + boundingBox.height / 2

				const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width)

				ctx.font = `bold ${fontSize}px sans-serif`
				ctx.textAlign = 'center'

				ctx.lineWidth = 3
				ctx.strokeStyle = '#35495e'
				ctx.strokeText(detectedCode.rawValue, centerX, centerY)

				ctx.fillStyle = '#5cb984'
				ctx.fillText(rawValue, centerX, centerY)
			}
		},

		switchCamera() {
			//카메라 변경
			switch (this.camera) {
				case 'front':
					this.camera = 'rear'
					break
				case 'rear':
					this.camera = 'front'
					break
			}
		},
		async logErrors(promise) {
			try {
				//카메라 변경
				await promise
			} catch (error) {
				const triedFrontCamera = this.camera === 'front'
				const triedRearCamera = this.camera === 'rear'

				const cameraMissingError = error.name === 'OverconstrainedError'

				if (triedRearCamera && cameraMissingError) {
					this.noRearCamera = true
				}

				if (triedFrontCamera && cameraMissingError) {
					this.noFrontCamera = true
				}

				console.error(error)
			}
			try {
				//플래시라이트 on off
				const { capabilities } = await promise

				this.torchNotSupported = !capabilities.torch
			} catch (error) {
				console.error(error)
			}
			try {
				//qr 읽기
				await promise
			} catch (e) {
				console.error(e)
			}
		},
		createAttendance(users) {
			const data = {
				interID: users.interID,
				Name: users.Name,
				work: new Date(Date.now()),
				workday: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
				position: users.position,
				phone: users.phone,
			}
			this.$store
				.dispatch('createAttendance', data)
				.then(() => {
					this.voice(users.Name + this.voiceSystemsList.StartWork.voice)
					this.alertCard1 = true
					setTimeout(() => {
						this.alertCard1 = false
					}, 3000)
				})
				.catch(err => {
					console.log({ err })
				})
		},
		updateAttendance(id, users) {
			if (!id) {
				return alert('error not found Id')
			}
			const data = {
				Leave: new Date(Date.now()),
				id: id,
			}
			this.$store
				.dispatch('updateAttendance', data)
				.then(() => {
					this.voice(users.Name + this.voiceSystemsList.LeaveWork.voice)
					this.alertCard3 = true
					setTimeout(() => {
						this.alertCard3 = false
					}, 3000)
				})
				.catch(err => {
					console.log({ err })
				})
		},
		Attendancelist_NoAuth(users) {
			if (!users) {
				return alert('error - not Users')
			}
			const data = {
				interID: users.interID,
				workday_gte: this.$moment(new Date()).format('YYYY-MM-') + '01',
			}
			this.$store
				.dispatch('Attendancelist_NoAuth', data)
				.then(res => {
					let totalHour = 0
					let totalDay = 0
					let monthWorkTime = 18 * 9
					let ok = 0
					res.attendances.forEach((attendance, index) => {
						if (this.$moment(attendance.work).day() !== 0 && this.$moment(attendance.work).day() !== 6) {
							let t2
							if (attendance.Leave) {
								t2 = this.$moment(attendance.Leave)
							} else {
								t2 = this.$moment(attendance.work).add(6, 'hours')
							}
							const t1 = this.$moment(attendance.work)
							totalHour += t2.diff(t1, 'minutes') / 60
							totalDay += 1
						}
						if (this.$moment(attendance.work).format('YYYY-MM-DD') === this.$moment(new Date()).format('YYYY-MM-DD')) {
							if (Date.now() > new Date(attendance.work).getTime() + 1000 * 60 * 60) {
								if (attendance.Leave === null) {
									this.updateAttendance(attendance.id, users)
								} else {
									this.voice(users.Name + this.voiceSystemsList.AlreadyLeaveWork.voice)
									this.alertCard4 = true
									setTimeout(() => {
										this.alertCard4 = false
									}, 3000)
								}
							} else {
								this.voice(users.Name + this.voiceSystemsList.AlreadyStartWork.voice)
								this.alertCard2 = true
								setTimeout(() => {
									this.alertCard2 = false
								}, 3000)
							}
							ok += 1
						}
						if (res.attendances.length - 1 === index) {
							console.log(users)
							if (ok === 0) {
								this.createAttendance(users)
							}
						}
					})
					if (res.attendances.length === 0) {
						this.createAttendance(users)
					}
					const notYears = this.$moment(this.$moment().format('YYYY-MM-DD')).isBetween(
						this.$moment(users.startdate).format('YYYY-MM-DD'),
						this.$moment(users.startdate)
							.add(1, 'years')
							.format('YYYY-MM-DD'),
						undefined,
						'[]',
					)
					if (monthWorkTime * 0.8 <= totalHour && totalDay >= 18 && notYears) {
						// this.updateUser_NoAuth(users)
					}
				})
				.catch(err => {
					console.log({ err })
				})
		},
		QRUser(content) {
			if (!content) {
				return alert('Qrcode Reload Please')
			}
			const data = {
				interID: content,
			}
			this.$store
				.dispatch('QRUser', data)
				.then(res => {
					console.log(res)
					if (res.users.length !== 0) {
						this.Attendancelist_NoAuth(res.users[0])
					} else {
						alert('직원이 아닙니다.')
					}
					this.pause()
					setTimeout(() => {
						this.unpause()
					}, 500)
				})
				.catch(err => {
					alert('오류 발생 다시 시도해 주세요.')
					console.log({ err })
					this.pause()
					setTimeout(() => {
						this.unpause()
					}, 500)
				})
		},
		async onDecode(content) {
			if (!content) {
				return alert('QRcode Reload please')
			}
			document.getElementById('hiddenBtn').click()
			let nowCamera = this.camera
			this.QRUser(content)
			await this.timeout(1000)
			this.camera = await nowCamera
			await this.timeout(1000)
		},
		unpause() {
			this.camera = 'auto'
		},
		pause() {
			this.camera = 'off'
		},
		timeout(ms) {
			return new Promise(resolve => {
				window.setTimeout(resolve, ms)
			})
		},

		hides() {},
	},
}
</script>

<style lang="scss">
.qrReader {
	height: 100vh !important;
}
/// 상단 메뉴 css
.qrHead {
	height: 9%;
	padding: 2vh 0px 0vw 2vw;
}
.qrHeadxs {
	height: 7%;
	padding: 2vh 0px 0vw 2vw;
}
// 상단 사업지명
.qrTitle {
	font-size: 4vh;
	font-weight: bold;
	color: white;
	border-radius: 10px;
	padding: 0.2vw 0px 0px 1vw;
}
.qrTitlexs {
	font-size: 3vh;
	font-weight: bold;
	color: white;
	border: 3px solid white;
	border-radius: 10px;
	padding: 0.2vw 0px 0px 1vw;
}
// 상단 버튼
.iconBtn {
	cursor: pointer;
	margin: 0px 5vw 0px 0px;
}
/// 인증완료 메세지 div
.confirmation {
	z-index: 1;
	position: inherit;
	width: 100%;
	text-align: -webkit-center;
	padding-top: 4vh;
}
/// 인증완료 메세지 카드
.alertCard1 {
	opacity: 0.7;
	width: 25vh;
	font-size: 2vh;
	padding: 10px;
	border-radius: 10px !important;
}
.alertCard2 {
	color: white !important;
	opacity: 0.7;
	width: 40vh;
	font-size: 2vh;
	padding: 10px;
	border-radius: 10px !important;
}
/// qr 인식 layout
.qrMiddle {
	z-index: 2;
	height: 84%;
}
.qrMiddlexs {
	z-index: 2;
	height: 86%;
}
// qr 인식 박스
.middleSquare {
	border: 4px dashed yellow;
	width: 30%;
	height: 69.5%;
}
.middleSquaremd {
	border: 4px dashed yellow;
	width: 48vw;
	height: 55vh;
}
.middleSquaresm {
	border: 4px dashed yellow;
	width: 58vw;
	height: 50vh;
}
.middleSquarexs {
	border: 4px dashed yellow;
	width: 63vw;
	height: 30vh;
}
.middleSquarexxs {
	border: 4px dashed yellow;
	width: 71vw;
	height: 30vh;
}
.middleSquarexxxs {
	border: 4px dashed yellow;
	width: 71vw;
	height: 25.5vh;
}
.hideBtn {
	display: none;
}
</style>

<template>
	<div style="height: 100vh; overflow: hidden;">
		<v-app-bar color="MainColor1" dark dense>
			<v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
			<v-img v-if="$store.state.systems.logo" :src="server + $store.state.systems.logo.url" max-width="48"></v-img>
			<v-spacer></v-spacer>

			<v-btn class="mr-2" color="PointColor2" dark @click="memos.dialog = true">
				<v-icon>mdi-file-document-edit-outline</v-icon>
			</v-btn>

			<div class="text-center">
				<v-dialog v-model="memos.dialog" fullscreen persistent>
					<v-card>
						<v-btn class="close-btn" color="dark" text @click="memos.dialog = false">
							&times;
						</v-btn>

						<v-card-text class="memo-card-text">
							<Board v-if="memos.dialog" />
						</v-card-text>
					</v-card>
				</v-dialog>
			</div>

			<v-badge
				overlap
				left
				bordered
				color="PointColor1"
				light
				rounded
				bottom
				:content="ceoReportBage + selfReportBage + teamReportBage"
				:value="ceoReportBage + selfReportBage + teamReportBage"
			>
				<v-btn dark class="mr-2" color="PointColor2" @click=";(reportDrawer = !reportDrawer), selfReports(), reportQuses(0)">
					<v-icon>mdi-circle-edit-outline</v-icon>
				</v-btn>
			</v-badge>
			<v-badge overlap left bordered color="PointColor1" light rounded bottom :content="AlertCount" :value="AlertCount">
				<v-btn color="PointColor2" @click="chatDrawer = !chatDrawer" dark>
					<v-icon>mdi-android-messages</v-icon>
				</v-btn>
			</v-badge>
		</v-app-bar>

		<v-navigation-drawer v-model="drawer" absolute temporary dark color="PointColor2">
			<v-list nav dense>
				<v-list-item-group v-model="group" active-class="white--text">
					<v-list-item v-for="(btn, i) in $store.state.navList" :key="i" @click="clickTo(btn.name, btn.to)">
						<v-list-item-icon v-show="btn.icon">
							<v-icon>{{ btn.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ btn.name }}</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
		<v-navigation-drawer
			v-model="chatDrawer"
			absolute
			right
			color="PointColor2"
			dark
			touchless
			stateless
			:width="$vuetify.breakpoint.xs ? '100%' : chatNavWidth"
			style="z-index: 10;"
		>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="text-h6">
						<v-layout align-center>
							Chat System
							<v-spacer></v-spacer>
							<v-select
								@change="chatTypeChange"
								hideDetails
								item-color="PointColor2"
								class="ml-2"
								:items="chatItems"
								value="All"
								solo
								dense
							></v-select>
							<v-btn icon @click="chatDrawerClose" class="ml-2">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-layout>
					</v-list-item-title>
					<v-list-item-subtitle>
						{{ subTitle }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>
			<v-tabs-items v-model="tab" dark class="tab-color">
				<v-tab-item v-show="tab === 0">
					<v-list nav dense>
						<v-list-item-group>
							<v-list-item @click="Alert()" v-if="$store.state.myData.cls === '1'">
								<v-icon class="mr-2">
									mdi-alert-circle
								</v-icon>
								Alert
								<v-spacer></v-spacer>
								<v-btn x-small color="PointColor1" fab elevation="0">
									{{ AlertCount }}
								</v-btn>
							</v-list-item>
							<v-list-item
								v-for="(chat, chatIndex) in chatList"
								:key="chatIndex"
								v-show="filterType === 'All' ? true : filterType.toUpperCase() === chat.type.toUpperCase()"
								@click="chatApply(chat)"
							>
								<v-list-item-title>
									<v-icon class="mr-2">{{ chat.type === 'team' ? 'mdi-account-group' : 'mdi-projector-screen-outline' }} </v-icon>
									{{ chat.title }} - <span class="PointColor1--text">({{ chat.member.length }})</span></v-list-item-title
								>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-tab-item>
				<!-- chat -->
				<v-tab-item v-show="tab === 1">
					<div class="pa-1">
						<v-tabs v-model="workTab" grow v-if="chatType === 'project'" background-color="PointColor2">
							<v-tabs-slider color="white"></v-tabs-slider>
							<v-tab>chat</v-tab>
							<v-tab>workList</v-tab>
						</v-tabs>
						<v-tabs-items v-model="workTab" dark class="tab-color">
							<v-tab-item>
								<btn :btn="back" btn_icon="mdi-chevron-left" @click="disconnectSocket" />
								<div class="chattingCard" id="chatting">
									<div v-for="(chat, chatIndex) in chats" :key="chatIndex">
										<v-card v-if="chat.show" color="PointColor2" outlined>
											<v-layout align-center class="px-4" justify-end v-if="$store.state.myData.Name === chat.user">
												<div class="chat-layout" style="text-align: -webkit-right;">
													<div class="name-date my">
														{{ chat.user }}, {{ chat.created_at | moment('YYYY-MM-DD HH:mm:ss') }}
														<v-btn class="ml-1" color="PointColor1" x-small @click="noshowChatDialog(chat)" icon>
															<v-icon>mdi-close</v-icon>
														</v-btn>
													</div>
													<v-card
														color="white"
														v-show="!imagesTypes.includes(src.split('.')[src.split('.').length - 1].toLowerCase())"
														v-for="(src, i) in chat.images"
														:key="i"
														:src="src"
														class="cursor mb-1"
														:href="src"
														style="width: fit-content;"
														tag="div"
													>
														<v-layout align-center justify-end>
															<v-btn icon color="PointColor2">
																<v-icon>mdi-file-download</v-icon>
															</v-btn>
															<div class="mr-2">
																<a download>
																	{{ src.split('/')[src.split('/').length - 1] }}
																</a>
															</div>
														</v-layout>
													</v-card>
													<viewer :images="chat.images" v-if="chat.images.length > 0" class="img-viewer my">
														<img
															v-for="(src, i) in chat.images"
															:key="i"
															:src="src"
															class="cursor"
															v-show="imagesTypes.includes(src.split('.')[src.split('.').length - 1].toLowerCase())"
														/>
													</viewer>
													<div class="myContent" :style="`background-color:${$store.state.MainColor1};`" v-else>
														{{ chat.content }}
													</div>
												</div>
											</v-layout>
											<v-layout align-center class="px-4" v-else>
												<v-avatar size="36" color="white" class="mr-2">
													<img
														:src="server + chatting.member[chatting.member.map(x => x.Name).indexOf(chat.user)].face.url"
														alt="John"
														v-if="
															chatting.member.length > 0 &&
																chatting.member.map(x => x.Name).indexOf(chat.user) > -1 &&
																chatting.member[chatting.member.map(x => x.Name).indexOf(chat.user)].face
														"
													/>
													<img src="/image/img-profile-default-s.png" alt="John" v-else />
												</v-avatar>
												<div class="chat-layout">
													<div class="name-date">{{ chat.user }}, {{ chat.created_at | moment('YYYY-MM-DD HH:mm:ss') }}</div>
													<v-card
														color="white"
														v-show="!imagesTypes.includes(src.split('.')[src.split('.').length - 1].toLowerCase())"
														v-for="(src, i) in chat.images"
														:key="i"
														:src="src"
														class="cursor mb-1"
														:href="src"
														style="width: fit-content;"
														tag="div"
													>
														<v-layout align-center justify-end>
															<v-btn icon color="PointColor2">
																<v-icon>mdi-file-download</v-icon>
															</v-btn>
															<div class="mr-2">
																<a download>
																	{{ src.split('/')[src.split('/').length - 1] }}
																</a>
															</div>
														</v-layout>
													</v-card>
													<viewer :images="chat.images" v-if="chat.images.length > 0" class="img-viewer">
														<img
															v-for="(src, i) in chat.images"
															:key="i"
															:src="src"
															class="cursor"
															v-show="imagesTypes.includes(src.split('.')[src.split('.').length - 1].toLowerCase())"
														/>
													</viewer>
													<div class="content" v-else>
														{{ chat.content }}
													</div>
												</div>
											</v-layout>
										</v-card>
									</div>
								</div>
								<v-card color="white" class="my-2" v-show="$store.state.fileUploadProgress > 0">
									<v-layout align-center class="py-2 px-4" justify-center>
										<strong class="black--text mr-2">{{ Math.ceil($store.state.fileUploadProgress) }}%</strong>
										<v-progress-linear
											rounded
											v-model="$store.state.fileUploadProgress"
											color="PointColor1"
											height="6"
											style="max-width:100%;"
											class="mx-2"
										>
										</v-progress-linear>
										<div class="black--text ml-2" v-show="$store.state.fileUploadProgress < 100">Uploading!</div>
										<div class="PointColor1--text ml-2" v-show="$store.state.fileUploadProgress >= 100">
											<strong> Completed</strong>
										</div>
									</v-layout>
								</v-card>
								<v-textarea
									v-model="chat"
									autocomplete="off"
									solo
									prepend-inner-icon="mdi-file"
									@click:prepend-inner="fileClick"
									append-icon="mdi-send"
									@click:append="sendChat"
									@keydown.enter="sendChat"
									hideDetails
									height="90"
									no-resize
								/>
								<input type="file" id="file" @change="fileupload" style="display:none;" multiple />
							</v-tab-item>
							<v-tab-item>
								<btn :btn="back" btn_icon="mdi-chevron-left" @click="disconnectSocket" />
								<v-layout class="mx-2">
									<textField
										class="mr-2"
										v-model="work"
										:textField="workTextField"
										@input="workTextField['error-messages'] = ''"
										@keydown.enter="updateTodolist"
									/>
									<DatepickerRange :picker="endTimepicker" @input="endDate" :allowed_dates="deadLineDates"></DatepickerRange>
									<btn class="ml-2" :btn="workBtn" btn_txt="save" @click="updateTodolist" />
								</v-layout>
								<v-divider dark class="my-4"></v-divider>
								<v-layout align-center class="mx-2">
									<DatepickerRange :picker="workStartpicker" @input="workDate"></DatepickerRange>
									<span class="mx-3 white--text"> ~ </span>
									<DatepickerRange :picker="workEndpicker" :allowed_dates="workCouselor" @input="workDate"></DatepickerRange>
								</v-layout>
								<v-layout align-center class="ma-2" justify-end>
									<btn :btn="workBtn" btn_txt="refresh" @click="todolists()" />
									<v-spacer></v-spacer>
									<textField v-model="search" :textField="SearchTextField" />
								</v-layout>
								<datatable
									class="mx-2"
									:datatable="workDatatable"
									:search="search"
									@workAction="workActionDialog"
									@workActionDelete="workActionDeleteDialog"
									@updateComment="updateComment"
									@deleteComment="deleteCommentDialog"
								>
								</datatable>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</v-tab-item>
				<v-tab-item v-show="tab === 2">
					<div class="pa-1">
						<btn :btn="back" btn_icon="mdi-chevron-left" @click="previous" class="mb-1" />
						<h2 class="white--text mx-2">
							Vacation Request
						</h2>
						<v-layout align-center class="mx-2">
							<DatepickerRange :picker="alertStartpicker" @input="alertDate"></DatepickerRange>
							<span class="mx-3 white--text"> ~ </span>
							<DatepickerRange :picker="alertEndpicker" :allowed_dates="alertCouselor" @input="alertDate"></DatepickerRange>
						</v-layout>
						<v-layout align-center class="ma-2" justify-end>
							<btn :btn="workBtn" btn_txt="refresh" @click="vacations()" />
							<v-spacer></v-spacer>
							<textField v-model="alertSearch" :textField="SearchTextField" />
						</v-layout>
						<datatable
							class="mx-2"
							:datatable="alertDatatable"
							:search="alertSearch"
							@vacationOk="vacationOk"
							@vacationReject="vacationReject"
						>
						</datatable>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</v-navigation-drawer>
		<v-navigation-drawer
			v-model="reportDrawer"
			absolute
			right
			color="PointColor2"
			dark
			touchless
			stateless
			:width="$vuetify.breakpoint.xs ? '100%' : reportNavWidth"
			style="z-index: 10;"
		>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="text-h6">
						<v-layout align-center>
							Report System
							<v-spacer></v-spacer>
							<v-btn icon @click="reportDrawerClose" class="ml-2">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-layout>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>
			<div class="pa-1">
				<v-tabs v-model="reportTab" grow background-color="PointColor2">
					<v-tabs-slider color="white"></v-tabs-slider>
					<v-tab
						>Self Report
						<v-badge v-show="selfReportBage > 0" class="ml-2" color="PointColor1" :content="String(selfReportBage)"> </v-badge>
					</v-tab>
					<v-tab
						>CEO Report <v-badge v-show="ceoReportBage > 0" class="ml-2" color="PointColor1" :content="String(ceoReportBage)"> </v-badge
					></v-tab>
					<v-tab v-if="teamItems.title.length > 0"
						>Team Report <v-badge v-show="teamReportBage > 0" class="ml-2" color="PointColor1" :content="String(teamReportBage)"> </v-badge
					></v-tab>
				</v-tabs>
				<v-tabs-items v-model="reportTab" dark class="tab-color">
					<v-tab-item>
						<div class="pa-1 mt-4">
							<h2 class="white--text mx-2">Write [ {{ $store.state.systems.selfReport }} / month ({{ selfReportsMonth }}) ]</h2>
							<h3 class="mb-1 mx-2 white--text">
								selfReport Period:
								<strong class="PointColor1--text">
									{{ selfReportWorkDay[0] }} ~
									{{ selfReportWorkDay[1] }}
								</strong>
							</h3>
							<reportQus :data="reportQus" :submitBage="String(selfReportBage)" @create="createSelfReportDialog" v-if="selfReportAccess" />
							<v-divider class="my-4"></v-divider>
							<v-layout align-center class="mx-2">
								<DatepickerRange :picker="reportStartpicker" @input="reportStartpicker.menu = false"></DatepickerRange>
								<span class="mx-3 white--text"> ~ </span>
								<DatepickerRange :picker="reportEndpicker" :allowed_dates="reportCouselor" @input="reportDate(reportTab)"></DatepickerRange>
							</v-layout>
							<v-layout align-center class="ma-2" justify-end>
								<btn :btn="workBtn" btn_txt="refresh" @click="selfReports()" />
								<v-spacer></v-spacer>
								<textField v-model="reportSearch" :textField="SearchTextField" />
							</v-layout>
							<datatable class="mx-2" :datatable="reportDatatable" :search="reportSearch" @showSelfReport="showSelfReport">
							</datatable></div
					></v-tab-item>
					<v-tab-item
						><div class="pa-1 mt-4">
							<h2 class="white--text mx-2">Report [ {{ $store.state.systems.ceoReport }} / month ({{ selfReportsMonth }}) ]</h2>
							<h3 class="mb-1 mx-2 white--text">
								ceoReport Period:
								<strong class="PointColor1--text">
									{{ ceoReportWorkDay[0] }} ~
									{{ ceoReportWorkDay[1] }}
								</strong>
							</h3>
							<v-layout align-center class="mx-2">
								<DatepickerRange :picker="reportStartpicker" @input="reportStartpicker.menu = false"></DatepickerRange>
								<span class="mx-3 white--text"> ~ </span>
								<DatepickerRange :picker="reportEndpicker" :allowed_dates="reportCouselor" @input="reportDate(reportTab)"></DatepickerRange>
							</v-layout>
							<v-layout align-center class="ma-2" justify-end>
								<btn :btn="workBtn" btn_txt="refresh" @click="reports()" />
								<v-spacer></v-spacer>
								<textField v-model="reportSearch" :textField="SearchTextField" />
							</v-layout>
							<datatable class="mx-2" :datatable="reportDatatable" :search="reportSearch" @showSelfReport="showCeoReport"> </datatable>
						</div>
					</v-tab-item>
					<v-tab-item v-if="teamItems.title.length > 0"
						><div class="pa-1 mt-4">
							<v-select
								hideDetails
								item-color="PointColor2"
								class="mb-2"
								:items="teamItems.title"
								label="Team"
								v-model="teamItem"
								solo
								dense
							></v-select>
							<div v-if="teamItem !== ''">
								<v-layout>
									<h2 class="white--text mx-2">Write [ {{ $store.state.systems.teamReport }} / month ]</h2>
									<v-spacer></v-spacer>
									<div style="max-width:200px;" v-if="teamReportAccess">
										<v-select
											hideDetails
											item-color="PointColor2"
											class="mb-2"
											:items="teamUsers"
											label="Team"
											v-model="userItem"
											solo
											dense
										></v-select>
									</div>
								</v-layout>
								<h3 class="mb-1 mx-2 white--text">
									teamReport Period:
									<strong class="PointColor1--text">
										{{ teamReportWorkDay[0] }} ~
										{{ teamReportWorkDay[1] }}
									</strong>
								</h3>
								<reportQus
									:data="reportQus"
									:submitBage="String(teamUsers.length)"
									@create="createTeamReportDialog"
									v-if="teamReportAccess"
								/>
								<v-divider class="my-4"></v-divider>
								<v-layout align-center class="mx-2">
									<DatepickerRange :picker="reportStartpicker" @input="reportStartpicker.menu = false"></DatepickerRange>
									<span class="mx-3 white--text"> ~ </span>
									<DatepickerRange
										:picker="reportEndpicker"
										:allowed_dates="reportCouselor"
										@input="reportDate(reportTab)"
									></DatepickerRange>
								</v-layout>
								<v-layout align-center class="ma-2" justify-end>
									<btn :btn="workBtn" btn_txt="refresh" @click="teamReports(teamItems.id[teamItems.title.indexOf(teamItem)])" />
									<v-spacer></v-spacer>
									<textField v-model="reportSearch" :textField="SearchTextField" />
								</v-layout>
								<datatable class="mx-2" :datatable="reportDatatable" :search="reportSearch" @showSelfReport="showTeamReport"> </datatable>
							</div>
						</div>
					</v-tab-item>
				</v-tabs-items>
			</div>
		</v-navigation-drawer>
		<user />
		<router-view />
		<v-dialog v-model="rejectDialog.dialog" max-width="500px">
			<v-card>
				<v-card-title>
					Reject Vacation
				</v-card-title>
				<v-card-text>{{ rejectDialog.content }}</v-card-text>
				<v-layout justify-center class="mb-2">
					<textField v-model="rejectDialog.reject" :textField="rejectTextField" class="mx-6" />
				</v-layout>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="PointColor2" text @click="rejectDialog.dialog = false">
						cancel
					</v-btn>
					<v-btn color="error" @click="updateVacation" dark>
						reject
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<dialog1 :setDialog="setDialog1" @cancel="setDialog1.dialog = false" @ok="dialogEvents" />
		<reportQusDialog :data="reportQusDialog" @edit="editReport" />
	</div>
</template>
<script>
import { user } from '../admin'
import { btn, DatepickerRange, datatable, textField, dialog1, reportQus, reportQusDialog } from '@/components'
import Board from '@/views/memo/common/Board'
export default {
	components: {
		user,
		btn,
		DatepickerRange,
		datatable,
		textField,
		dialog1,
		reportQus,
		reportQusDialog,
		Board,
	},
	computed: {
		teamItems() {
			let setArray = {
				id: [],
				title: [],
			}
			if (this.$store.state.myData.teams) {
				this.$store.state.myData.teams.forEach(element => {
					if (element.teamPosition[0].leader === this.$store.state.myData.username) {
						setArray.id.push(element.id)
						setArray.title.push(element.title)
					}
				})
			}
			return setArray
		},
		selfReportAccess() {
			let ok = 0
			const thisMonthData = this.reportDatatable.items.filter(
				x => this.$moment(x.reportDay).format('YY-MM') === this.$moment().format('YY-MM'),
			)
			if (this.$store.state.systems.selfReport > thisMonthData.length) {
				if (this.$store.state.systems.selfReport === 1) {
					if (
						new Date(this.weekObjArray[this.weekObjArray.length - 1].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[this.weekObjArray.length - 1].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							ok = 0
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[this.weekObjArray.length - 1].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[this.weekObjArray.length - 1].weekEndDate) >= new Date(element.reportDay)
								) {
									ok += 1
								}
							})
						}
					}
				} else if (this.$store.state.systems.selfReport === 2) {
					if (new Date(this.weekObjArray[1].weekStartDate) <= new Date() && new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						if (thisMonthData.length === 0) {
							ok = 0
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[1].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[1].weekEndDate) >= new Date(element.reportDay)
								) {
									ok += 1
								}
							})
						}
					} else if (
						new Date(this.weekObjArray[3].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[3].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							ok = 0
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[3].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[3].weekEndDate) >= new Date(element.reportDay)
								) {
									ok += 1
								}
							})
						}
					}
				} else if (this.$store.state.systems.selfReport === 3) {
					if (new Date(this.weekObjArray[1].weekStartDate) <= new Date() && new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						if (thisMonthData.length === 0) {
							ok = 0
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[1].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[1].weekEndDate) >= new Date(element.reportDay)
								) {
									ok += 1
								}
							})
						}
					} else if (
						new Date(this.weekObjArray[2].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[2].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							ok = 0
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[2].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[2].weekEndDate) >= new Date(element.reportDay)
								) {
									ok += 1
								}
							})
						}
					} else if (
						new Date(this.weekObjArray[3].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[3].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							ok = 0
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[3].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[3].weekEndDate) >= new Date(element.reportDay)
								) {
									ok += 1
								}
							})
						}
					}
				} else if (this.$store.state.systems.selfReport === 4) {
					if (new Date(this.weekObjArray[0].weekStartDate) <= new Date() && new Date(this.weekObjArray[0].weekEndDate) >= new Date()) {
						if (thisMonthData.length === 0) {
							ok = 0
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[0].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[0].weekEndDate) >= new Date(element.reportDay)
								) {
									ok += 1
								}
							})
						}
					} else if (
						new Date(this.weekObjArray[1].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[1].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							ok = 0
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[1].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[1].weekEndDate) >= new Date(element.reportDay)
								) {
									ok += 1
								}
							})
						}
					} else if (
						new Date(this.weekObjArray[2].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[2].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							ok = 0
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[2].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[2].weekEndDate) >= new Date(element.reportDay)
								) {
									ok += 1
								}
							})
						}
					} else if (
						new Date(this.weekObjArray[3].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[3].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							ok = 0
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[3].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[3].weekEndDate) >= new Date(element.reportDay)
								) {
									ok += 1
								}
							})
						}
					}
				} else {
					ok += 1
				}
			}
			if (ok === 0) {
				return true
			} else {
				return false
			}
		},
		selfReportsMonth() {
			return this.reportDatatable.items.filter(x => this.$moment(x.reportDay).format('YY-MM') === this.$moment().format('YY-MM')).length
		},
	},
	updated() {
		const objDiv = document.getElementById('chatting')
		if (objDiv) {
			objDiv.scrollTop = objDiv.scrollHeight
		}
	},
	data() {
		return {
			selfReportWorkDay: [],
			ceoReportWorkDay: [],
			teamReportWorkDay: [],
			userItem: '',
			teamItem: '',
			reportQusDialog: {
				dialog: false,
				qusList: [],
			},
			reportQus: {
				qusList: [],
			},
			teamReportBage: 0,
			ceoReportBage: 0,
			selfReportBage: 0,
			ReportSubTitle: '',
			reportTab: 0,
			reportDrawer: false,
			reportNavWidth: 600,
			rejectDialog: {
				dialog: false,
				type: 'reject',
				content: '',
				reject: '',
			},
			setDialog1: {
				dialog: false,
				title: '',
				content: '',
				btnText: '',
				type: '',
			},
			workDatatable: {
				headers: [
					{ text: 'No', value: 'No', sortable: false },
					{ text: 'Title', value: 'title' },
					{ text: 'DeadLine', value: 'deadline' },
					{ text: 'User', value: 'user' },
					{ text: 'Status', value: 'status' },
					{ text: 'Action', value: 'workAction', sortable: false },
				],
				items: [],
				hidedefaultfooter: false,
				hidedefaultheader: false,
				singleExpand: true,
				expanded: [],
				['show-expand']: true,
				key: 'created_at',
				comment: '',
			},
			alertDatatable: {
				headers: [
					{ text: 'No', value: 'No', sortable: false },
					{ text: 'Name', value: 'users_permissions_user.Name' },
					{ text: 'Content', value: 'content' },
					{ text: 'date', value: 'vacationDate' },
					{ text: 'Action', value: 'vacationAlertAction', sortable: false },
				],
				items: [],
				hidedefaultfooter: false,
				hidedefaultheader: false,
				singleExpand: false,
			},
			reportDatatable: {
				headers: [
					{ text: 'No', value: 'No', sortable: false },
					{ text: 'Report Day', value: 'reportDay' },
					{ text: 'Comment', value: 'content' },
					{ text: 'Action', value: 'reportAction', sortable: false },
				],
				items: [],
				hidedefaultfooter: false,
				hidedefaultheader: false,
				singleExpand: false,
			},
			workBtn: {
				dark: true,
				color: 'PointColor1',
				height: 48,
			},
			work: '',
			workTextField: {
				dark: false,
				autofocus: false,
				width: '100%',
				['error-messages']: '',
				placeholder: 'Work Title',
				['prepend-inner-icon']: 'mdi-pencil',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			alertSearch: '',
			reportSearch: '',
			search: '',
			rejectTextField: {
				dark: false,
				autofocus: false,
				placeholder: 'Reject Reason',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			SearchTextField: {
				dark: false,
				width: 150,
				placeholder: 'Search',
				['prepend-inner-icon']: 'mdi-magnify',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			endTimepicker: {
				date: null,
				errorMessage: '',
				menu: false,
				backgroundColor: 'black',
				height: 48,
				placeholder: 'deadline',
				width: 130,
			},
			workStartpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'start',
				height: 48,
			},
			workEndpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'end',
				height: 48,
			},
			alertStartpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'start',
				height: 48,
			},
			alertEndpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'end',
				height: 48,
			},
			reportStartpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'start',
				height: 48,
			},
			reportEndpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'end',
				height: 48,
			},
			imagesTypes: ['jpg', 'png', 'jpeg', 'gif', 'bmp', 'rle', 'dib', 'tif', 'tiff'],
			server: process.env.VUE_APP_BACKEND_URL,
			chats: [],
			chatType: 'team',
			workTab: 0,
			chat: '',
			subTitle: 'chat List',
			chatNavWidth: 300,
			back: {
				fab: true,
				icon: true,
				small: true,
				color: 'transparent',
			},
			tab: 0,
			filterType: 'All',
			chatList: [],
			chatItems: ['All', 'Project', 'Team'],
			drawer: false,
			group: null,
			chatDrawer: false,
			chatting: {
				member: [],
			},
			todoListId: null,
			workList: [],
			alertList: [],
			deleteItem: {},
			actionItem: {},
			noShowChat: {},
			workItem: {},
			workItemComment: {},
			vacationId: null,
			AlertCount: 0,
			rejectUser: {},
			reportTypes: ['selfReport', 'ceoReport', 'teamReport'],
			weekObjArray: [],
			teamUsers: [],
			teamReportAccess: false,
			memos: {
				dialog: false,
			},
		}
	},
	watch: {
		teamItem(val) {
			const index = this.teamItems.title.indexOf(this.teamItem)
			this.teamReports(this.teamItems.id[index])
			this.teamReportsCount(val)
		},
		reportTab(val) {
			this.reportQus.tab = val
			this.reportQuses(val)
			this.reportSearch = ''
			if (val === 0) {
				this.selfReports()
			} else if (val === 1) {
				this.reports()
			} else if (val === 2) {
				const index = this.teamItems.title.indexOf(this.teamItem)
				this.teamReports(this.teamItems.id[index])
			}
			this.reportStartpicker.date = this.$moment(new Date())
				.subtract(45, 'days')
				.format('YYYY-MM-DD')
			this.reportEndpicker.date = this.$moment(new Date())
				.add(45, 'days')
				.format('YYYY-MM-DD')
		},
		chatDrawer() {
			if (this.$store.state.myData.cls === '1') {
				this.vacationsCount()
			}
		},
		workTab(val) {
			if (val === 1) {
				this.todolists()
			}
		},
	},
	methods: {
		teamReportAccessMethod() {
			let show = false
			if (this.$store.state.systems.teamReport === 1) {
				if (
					new Date(this.weekObjArray[this.weekObjArray.length - 1].weekStartDate) <= new Date() &&
					new Date(this.weekObjArray[this.weekObjArray.length - 1].weekEndDate) >= new Date()
				) {
					show = true
				} else {
					show = false
				}
			} else if (this.$store.state.systems.teamReport === 2) {
				if (new Date(this.weekObjArray[1].weekStartDate) <= new Date() && new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
					show = true
				} else if (new Date(this.weekObjArray[3].weekStartDate) <= new Date() && new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
					show = true
				} else {
					show = false
				}
			} else if (this.$store.state.systems.teamReport === 3) {
				if (new Date(this.weekObjArray[1].weekStartDate) <= new Date() && new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
					show = true
				} else if (new Date(this.weekObjArray[2].weekStartDate) <= new Date() && new Date(this.weekObjArray[2].weekEndDate) >= new Date()) {
					show = true
				} else if (new Date(this.weekObjArray[3].weekStartDate) <= new Date() && new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
					show = true
				} else {
					show = false
				}
			} else if (this.$store.state.systems.teamReport === 4) {
				if (new Date(this.weekObjArray[0].weekStartDate) <= new Date() && new Date(this.weekObjArray[0].weekEndDate) >= new Date()) {
					show = true
				} else if (new Date(this.weekObjArray[1].weekStartDate) <= new Date() && new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
					show = true
				} else if (new Date(this.weekObjArray[2].weekStartDate) <= new Date() && new Date(this.weekObjArray[2].weekEndDate) >= new Date()) {
					show = true
				} else if (new Date(this.weekObjArray[3].weekStartDate) <= new Date() && new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
					show = true
				} else {
					show = false
				}
			} else {
				show = false
			}
			return (this.teamReportAccess = show)
		},
		systems() {
			this.$store
				.dispatch('systems')
				.then(res => {
					if (res.systems.length > 0) {
						this.$store.state.systemsNew = false
						if (res.systems[0].logo) {
							this.$store.state.systems.logo = res.systems[0].logo
						}
						this.$store.state.systems.id = res.systems[0].id
						this.$store.state.systems.color = res.systems[0].color
						const ColorArray = ['MainColor1', 'MainColor2', 'PointColor1', 'PointColor2', 'PointColor3', 'FontColor1', 'FontColor2']
						ColorArray.forEach(element => {
							this.$store.state[element] = res.systems[0].color[element]
							this.$vuetify.theme.themes.light[element] = res.systems[0].color[element]
						})
						const reprotPeriods = []
						reprotPeriods.push(res.systems[0].selfReport)
						reprotPeriods.push(res.systems[0].ceoReport)
						reprotPeriods.push(res.systems[0].teamReport)
						this.$store.state.systems.ceoReport = res.systems[0].ceoReport
						this.$store.state.systems.teamReport = res.systems[0].teamReport
						this.$store.state.systems.selfReport = res.systems[0].selfReport

						reprotPeriods.forEach((element, index) => {
							let show = []
							if (this.weekObjArray.length > 0) {
								if (element === 1) {
									show.push(this.weekObjArray[this.weekObjArray.length - 1].weekStartDate)
									show.push(this.weekObjArray[this.weekObjArray.length - 1].weekEndDate)
								} else if (element === 2) {
									if (new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
										show.push(this.weekObjArray[1].weekStartDate)
										show.push(this.weekObjArray[1].weekEndDate)
									} else if (new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
										show.push(this.weekObjArray[3].weekStartDate)
										show.push(this.weekObjArray[3].weekEndDate)
									}
								} else if (element === 3) {
									if (new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
										show.push(this.weekObjArray[1].weekStartDate)
										show.push(this.weekObjArray[1].weekEndDate)
									} else if (new Date(this.weekObjArray[2].weekEndDate) >= new Date()) {
										show.push(this.weekObjArray[2].weekStartDate)
										show.push(this.weekObjArray[2].weekEndDate)
									} else if (new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
										show.push(this.weekObjArray[3].weekStartDate)
										show.push(this.weekObjArray[3].weekEndDate)
									}
								} else if (element === 4) {
									if (new Date(this.weekObjArray[0].weekEndDate) >= new Date()) {
										show.push(this.weekObjArray[0].weekStartDate)
										show.push(this.weekObjArray[0].weekEndDate)
									} else if (new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
										show.push(this.weekObjArray[1].weekStartDate)
										show.push(this.weekObjArray[1].weekEndDate)
									} else if (new Date(this.weekObjArray[2].weekEndDate) >= new Date()) {
										show.push(this.weekObjArray[2].weekStartDate)
										show.push(this.weekObjArray[2].weekEndDate)
									} else if (new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
										show.push(this.weekObjArray[3].weekStartDate)
										show.push(this.weekObjArray[3].weekEndDate)
									}
								}
							}
							if (index === 0) {
								this.selfReportWorkDay = show
							} else if (index === 1) {
								this.ceoReportWorkDay = show
							} else if (index === 2) {
								this.teamReportWorkDay = show
							}
						})
						this.teamReportAccessMethod()
					} else {
						this.$store.state.systemsNew = true
					}
				})
				.catch(err => {
					console.log({ err })
				})
		},
		RankText(data) {
			if (data === 0) {
				return 'D'
			} else if (data === 1) {
				return 'C'
			} else if (data === 2) {
				return 'B'
			} else if (data === 3) {
				return 'A'
			} else if (data === 4) {
				return 'S'
			} else {
				return ''
			}
		},
		editReport(val, item) {
			val
			if (item.type === 'selfReport') {
				this.updateSelfReport(item.id)
			} else if (item.type === 'teamReport') {
				this.updateTeamReport(item.id)
			}
		},
		showTeamReport(val, item) {
			val
			this.reportQusDialog.dialog = true
			this.reportQusDialog.type = 'teamReport'
			this.reportQusDialog.reportDay = item.reportDay
			this.reportQusDialog.readOnly = false
			this.reportQusDialog.id = item.id
			this.reportQusDialog.qusList = item.data
		},
		showSelfReport(val, item) {
			val
			this.reportQusDialog.dialog = true
			this.reportQusDialog.type = 'selfReport'
			this.reportQusDialog.readOnly = false
			this.reportQusDialog.reportDay = item.reportDay
			this.reportQusDialog.id = item.id
			this.reportQusDialog.qusList = item.data
		},
		showCeoReport(val, item) {
			val
			this.reportQusDialog.dialog = true
			this.reportQusDialog.type = 'ceoReportAdmin'
			this.reportQusDialog.readOnly = true
			this.reportQusDialog.reportDay = item.reportDay
			this.reportQusDialog.id = item.id
			this.reportQusDialog.qusList = item.data
		},
		createSelfReport() {
			if (this.$store.state.myData.id) {
				const data = {
					data: this.reportQus.qusList,
					user: this.$store.state.myData.id,
					reportDay: this.$moment().format('YYYY-MM-DD'),
				}
				this.$store
					.dispatch('createSelfReport', data)
					.then(() => {
						this.selfReports()
						this.setDialog1.dialog = false
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		updateSelfReport(id) {
			if (this.$store.state.myData.id) {
				const data = {
					data: this.reportQusDialog.qusList,
					id: id,
				}
				this.$store
					.dispatch('updateSelfReport', data)
					.then(() => {
						this.selfReports()
						this.reportQusDialog.dialog = false
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		createTeamReport() {
			const index = this.teamItems.title.indexOf(this.teamItem)
			const index2 = this.teamUsers.indexOf(this.userItem)
			if (this.$store.state.myData.id) {
				if (!this.reportQus.rank) {
					return alert('required Ranks')
				}
				const data = {
					data: this.reportQus.qusList,
					user: this.teamUsersId[index2],
					reportDay: this.$moment().format('YYYY-MM-DD'),
					creator: this.$store.state.myData.email,
					rank: this.reportQus.rank,
					team: this.teamItems.id[index],
				}
				this.$store
					.dispatch('createTeamReport', data)
					.then(() => {
						const index = this.teamItems.title.indexOf(this.teamItem)
						this.teamReports(this.teamItems.id[index])
						this.setDialog1.dialog = false
						this.reportQuses(2)
						this.reportQus.rank = null
						this.teamReportsCount(this.teamItem)
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		updateTeamReport(id) {
			if (this.$store.state.myData.id) {
				const data = {
					data: this.reportQusDialog.qusList,
					id: id,
				}
				this.$store
					.dispatch('updateTeamReport', data)
					.then(() => {
						const index = this.teamItems.title.indexOf(this.teamItem)
						this.teamReports(this.teamItems.id[index])
						this.reportQusDialog.dialog = false
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		createTeamReportDialog() {
			this.setDialog1.dialog = true
			this.setDialog1.type = 'teamReportSumit'
			this.setDialog1.title = 'team Report Sumit'
			this.setDialog1.content = 'Are you really sure to sumit Team Report?'
			this.setDialog1.btnText = 'Sumit'
		},
		createSelfReportDialog() {
			this.setDialog1.dialog = true
			this.setDialog1.type = 'selfReportSumit'
			this.setDialog1.title = 'self Report Sumit'
			this.setDialog1.content = 'Are you really sure to sumit Self Report?'
			this.setDialog1.btnText = 'Sumit'
		},
		reportQuses(val) {
			const data = {
				qusType: this.reportTypes[val],
				show: true,
			}
			this.$store
				.dispatch('reportQuses', data)
				.then(res => {
					this.reportQus.qusList = res.reportQuses
				})
				.catch(err => {
					console.log({ err })
				})
		},
		selfReports() {
			if (this.$store.state.myData.id) {
				const data = {
					user: this.$store.state.myData.id,
					reportDay_gte: this.reportStartpicker.date,
					reportDay_lte: this.reportEndpicker.date,
				}
				this.$store
					.dispatch('selfReports', data)
					.then(res => {
						this.reportDatatable.headers = [
							{ text: 'No', value: 'No', sortable: false },
							{ text: 'Report Day', value: 'reportDay' },
							{ text: 'Comment', value: 'content' },
							{ text: 'Action', value: 'reportAction', sortable: false },
						]
						this.reportDatatable.items = res.selfReports
						if (this.selfReportAccess) {
							this.selfReportBage = 1
						} else {
							this.selfReportBage = 0
						}
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		teamReports(id) {
			if (this.$store.state.myData.email) {
				const data = {
					team: id,
					creator: this.$store.state.myData.email,
					reportDay_gte: this.reportStartpicker.date,
					reportDay_lte: this.reportEndpicker.date,
				}
				this.$store
					.dispatch('teamReports', data)
					.then(res => {
						this.reportDatatable.headers = [
							{ text: 'No', value: 'No', sortable: false },
							{ text: 'Report Day', value: 'reportDay' },
							{ text: 'Team', value: 'team.title' },
							{ text: 'User', value: 'users_permissions_user.Name' },
							{ text: 'Comment', value: 'content' },
							{ text: 'Action', value: 'reportAction', sortable: false },
						]
						this.reportDatatable.items = res.teamReports
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		teamReportsCount(val) {
			if (this.$store.state.myData.email) {
				if (this.teamReportAccess) {
					this.weekObjArray.forEach(element => {
						if (new Date(element.weekStartDate) <= new Date() && new Date(element.weekEndDate) >= new Date()) {
							const data = {
								creator: this.$store.state.myData.email,
								reportDay_gte: this.$moment(element.weekStartDate).format('YYYY-MM-DD'),
								reportDay_lte: this.$moment(element.weekEndDate).format('YYYY-MM-DD'),
							}
							this.$store
								.dispatch('teamReports', data)
								.then(res => {
									this.teamReportBage = 0
									this.$store.state.myData.teams.forEach(team => {
										this.teamReportBage += team.users_permissions_users.length
										const setArray = res.teamReports.filter(x => x.team.id === team.id)
										if (team.title === val) {
											this.teamUsersId = team.users_permissions_users.map(x => x.id)
											this.teamUsers = team.users_permissions_users.map(x => x.Name + '/' + x.username)
										}
										setArray.forEach(element => {
											const index = this.teamUsers.indexOf(
												element.users_permissions_user.Name + '/' + element.users_permissions_user.username,
											)
											this.teamUsers.splice(index, 1)
											this.teamUsersId.splice(index, 1)
											this.teamReportBage -= 1
										})
									})
								})
								.catch(err => {
									console.log({ err })
								})
						}
					})
				}
			}
		},
		reports() {
			if (this.$store.state.myData.id) {
				const data = {
					user: this.$store.state.myData.id,
					reportDay_gte: this.reportStartpicker.date,
					reportDay_lte: this.reportEndpicker.date,
				}
				this.$store
					.dispatch('reports', data)
					.then(res => {
						this.reportDatatable.headers = [
							{ text: 'No', value: 'No', sortable: false },
							{ text: 'Report Day', value: 'reportDay' },
							{ text: 'Rank', value: 'rankText' },
							{ text: 'Action', value: 'reportAction', sortable: false },
						]
						this.reportDatatable.items = []
						res.reports.forEach(element => {
							element.rankText = this.RankText(element.rank)
						})
						this.reportDatatable.items = res.reports
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		reportsCount() {
			if (this.$store.state.myData.id) {
				this.weekObjArray.forEach(element => {
					if (new Date(element.weekStartDate) <= new Date() && new Date(element.weekEndDate) >= new Date()) {
						const data = {
							user: this.$store.state.myData.id,
							reportDay_gte: this.$moment(element.weekStartDate).format('YYYY-MM-DD'),
							reportDay_lte: this.$moment(element.weekEndDate).format('YYYY-MM-DD'),
						}
						this.$store
							.dispatch('reports', data)
							.then(res => {
								this.ceoReportBage = res.reports.length
							})
							.catch(err => {
								console.log({ err })
							})
					}
				})
			}
		},
		vacationsCount() {
			const data = {
				status: 1,
			}
			this.$store.dispatch('vacationsCount', data).then(res => {
				this.AlertCount = res.vacationsCount
			})
		},
		updateUser() {
			this.$store.dispatch('me').then(res => {
				let data
				const t1 = this.$moment(this.rejectUser.vacation)
				const t2 = this.$moment(this.rejectUser.vacationEnd)
				t2.diff(t1, 'days')
				if (this.rejectUser.halfDay === null) {
					data = {
						id: this.rejectUser.users_permissions_user.id,
						vacationsDays: res.vacationsDays + t2.diff(t1, 'days') + 1,
					}
				} else {
					data = {
						id: this.rejectUser.users_permissions_user.id,
						vacationsDays: res.vacationsDays + (this.rejectUser.halfDay === 0 ? 0.5 : 1),
					}
				}
				this.$store
					.dispatch('updateUser', data)
					.then(() => {})
					.catch(err => {
						console.log({ err })
					})
			})
		},
		updateVacation() {
			let data
			if (this.rejectDialog.type === 'reject') {
				data = {
					id: this.vacationId,
					approval: this.$store.state.myData.Name + '/' + this.$store.state.myData.email,
					approvalDate: this.$moment().format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
					reject: this.rejectDialog.reject,
					status: 2,
				}
			} else {
				data = {
					id: this.vacationId,
					approval: this.$store.state.myData.Name + '/' + this.$store.state.myData.email,
					approvalDate: this.$moment().format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
					status: 3,
				}
			}
			this.$store
				.dispatch('updateVacation', data)
				.then(() => {
					if (this.rejectDialog.type === 'reject') {
						// this.updateUser()
					}
					this.rejectDialog.reject = ''
					this.rejectDialog.dialog = false
					this.setDialog1.dialog = false
					this.vacations()
				})
				.catch(err => {
					console.log({ err })
				})
		},
		vacationReject(val, item) {
			val
			this.rejectUser = item
			this.vacationId = item.id
			this.rejectDialog.dialog = true
			this.rejectDialog.type = 'reject'
			this.rejectDialog.content =
				'Are you really sure to ' +
				item.users_permissions_user.Name +
				'(' +
				this.$moment(item.vacation).format('YYYY-MM-DD') +
				' ~ ' +
				this.$moment(item.vacationEnd).format('YYYY-MM-DD') +
				')' +
				' vacation Reject?'
		},
		vacationOk(val, item) {
			val
			this.vacationId = item.id
			this.rejectDialog.type = 'ok'
			this.setDialog1.dialog = true
			this.setDialog1.type = 'vacationOk'
			this.setDialog1.title = 'Vacation Ok'
			this.setDialog1.content =
				'Are you really sure to ' +
				item.users_permissions_user.Name +
				'(' +
				this.$moment(item.vacation).format('YYYY-MM-DD') +
				' ~ ' +
				this.$moment(item.vacationEnd).format('YYYY-MM-DD') +
				')' +
				' vacation Ok?'
			this.setDialog1.btnText = 'Ok'
		},
		vacations() {
			const data = {
				vacation_gte: this.alertStartpicker.date + 'T00:00:00.000Z',
				vacation_lte: this.alertEndpicker.date + 'T00:00:00.000Z',
				status: 1,
			}
			this.$store
				.dispatch('vacations', data)
				.then(res => {
					this.alertDatatable.items = res.vacations
				})
				.catch(err => {
					console.log({ err })
				})
		},
		Alert() {
			this.tab = 2
			this.subTitle = 'Alert'
			this.chatNavWidth = 600
			if (this.$store.state.myData.cls === '1') {
				this.vacations()
			}
		},
		clickTo(name, to) {
			if (name === 'Logout') {
				this.logout()
			} else {
				this.$router.push({ name: to }).catch(() => {})
			}
		},
		logout() {
			sessionStorage.removeItem('protect-t')
			this.$router.push({ name: 'login' })
		},
		deadLineDates(val) {
			let start = this.$moment().format('YYMMDD')

			let startDate = new Date(this.workStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 60))
			let endDate = this.$moment(end).format('YYMMDD')

			val = this.$moment(val).format('YYMMDD') <= endDate && this.$moment(val).format('YYMMDD') >= start
			return val
		},
		deleteCommentDialog(val, item, chat) {
			val
			this.workItem = item
			this.workItemComment = chat
			this.setDialog1.dialog = true
			this.setDialog1.title = 'No-show comment'
			this.setDialog1.type = 'no-show-comment'
			this.setDialog1.content = 'Are you really sure to no-show this comment? - "' + chat.comment + '"'
			this.setDialog1.btnText = 'no-show'
		},
		deleteComment(item, chat) {
			this.$store.state.loading = true
			const data = { interID: this.chatting.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const lists = res.todolists[0].list
					const listIndex = lists.map(x => x.created_at).indexOf(item.created_at)
					const commentIndex = lists[listIndex].comments.map(y => y.created_at).indexOf(chat.created_at)
					lists[listIndex].comments[commentIndex].show = !lists[listIndex].comments[commentIndex].show
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.todolists()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		updateComment(val, item) {
			if (this.workDatatable.comment !== '') {
				val
				this.$store.state.loading = true
				const data = { interID: this.chatting.interID }
				this.$store
					.dispatch('todolists', data)
					.then(res => {
						const lists = res.todolists[0].list
						const listIndex = lists.map(x => x.created_at).indexOf(item.created_at)
						lists[listIndex].comments.push({
							created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
							comment: this.workDatatable.comment,
							user: this.$store.state.myData.Name,
							email: this.$store.state.myData.email,
							show: true,
						})
						const data = {
							id: this.todoListId,
							list: lists,
						}
						this.$store
							.dispatch('updateTodolist', data)
							.then(() => {
								this.$store.state.loading = false
								this.workDatatable.comment = ''
								this.todolists()
							})
							.catch(err => {
								this.$store.state.loading = false
								console.log({ err })
							})
					})
					.catch(err => {
						this.$store.state.loading = false
						console.log({ err })
					})
			}
		},
		noshowChatDialog(chat) {
			this.noShowChat = chat
			this.setDialog1.dialog = true
			this.setDialog1.type = 'no-show'
			this.setDialog1.title = 'No-show chatting'
			this.setDialog1.content = 'Are you really sure to no-show this chat?'
			this.setDialog1.btnText = 'no-show'
		},
		dialogEvents() {
			if (this.setDialog1.type === 'workActionDelete') {
				this.workActionDelete(this.deleteItem)
			} else if (this.setDialog1.type === 'report' || this.setDialog1.type === 'return') {
				this.workAction(this.actionItem)
			} else if (this.setDialog1.type === 'no-show') {
				this.chats.filter(x => x.show)[
					this.chats
						.filter(x => x.show)
						.map(x => x.created_at)
						.indexOf(this.noShowChat.created_at)
				].show = false
				this.$socket.emit('chatting', {
					chats: this.chats,
				})
				this.$socket.emit('chatupdate', this.chats)
				this.setDialog1.dialog = false
			} else if (this.setDialog1.type === 'no-show-comment') {
				this.deleteComment(this.workItem, this.workItemComment)
			} else if (this.setDialog1.type === 'vacationOk') {
				this.updateVacation()
			} else if (this.setDialog1.type === 'selfReportSumit') {
				this.createSelfReport()
			} else if (this.setDialog1.type === 'teamReportSumit') {
				this.createTeamReport()
			}
		},
		workActionDialog(val, item) {
			val
			this.actionItem = item
			this.setDialog1.dialog = true
			if (item.status === 1) {
				this.setDialog1.title = 'report to Leader'
				this.setDialog1.type = 'report'
				this.setDialog1.content = 'Are you really sure to report to ' + item.title + '?'
				this.setDialog1.btnText = 'report'
			} else if (item.status === 3) {
				this.setDialog1.title = 'get back to working'
				this.setDialog1.type = 'return'
				this.setDialog1.content = 'Are you really sure to get back ' + item.title + '?'
				this.setDialog1.btnText = 'get back'
			}
		},
		workActionDeleteDialog(val, item) {
			val
			this.deleteItem = item
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Work List Delete'
			this.setDialog1.type = 'workActionDelete'
			this.setDialog1.content = 'Are you really sure to delete ' + item.title + '?'
			this.setDialog1.btnText = 'delete'
		},
		workAction(item) {
			this.$store.state.loading = true
			const data = { interID: this.chatting.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const lists = res.todolists[0].list
					if (lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status === 1) {
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status = 2
					} else if (lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status === 3) {
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status = 1
					}
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.todolists()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		workActionDelete(item) {
			this.$store.state.loading = true
			const data = { interID: this.chatting.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const lists = res.todolists[0].list
					lists.splice(res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at), 1)
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.todolists()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		todolists() {
			if (!this.chatting.interID) {
				return alert('error - no inter ID')
			}
			this.$store.state.loading = true
			const data = { interID: this.chatting.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					this.setDialog1.dialog = false
					this.$store.state.loading = false
					if (res.todolists.length > 0) {
						this.todoListId = res.todolists[0].id
						this.workList = JSON.parse(JSON.stringify(res.todolists[0].list))
						this.workDatatable.items = res.todolists[0].list
							.filter(
								x =>
									new Date(x.deadline) >= new Date(this.workStartpicker.date) && new Date(x.deadline) <= new Date(this.workEndpicker.date),
							)
							.reverse()
					} else {
						this.workDatatable.items = []
					}
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		updateTodolist() {
			if (this.work === '') {
				return (this.workTextField['error-messages'] = 'check')
			}
			if (!this.endTimepicker.date) {
				return (this.endTimepicker.errorMessage = 'check')
			}
			this.$store.state.loading = true
			const data = { interID: this.chatting.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					let lists
					if (res.todolists.length > 0) {
						lists = res.todolists[0].list
					} else {
						lists = []
					}
					lists.push({
						created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
						title: this.work,
						deadline: this.endTimepicker.date,
						status: 1,
						user: this.$store.state.myData.Name,
						email: this.$store.state.myData.email,
						checker: [],
						comments: [],
					})
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.work = ''
							this.endTimepicker.date = null
							this.todolists()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		workCouselor(val) {
			let start = this.$moment(this.workStartpicker.date).format('YYMMDD')
			let startDate = new Date(this.workStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 90))
			let endDate = this.$moment(end).format('YYMMDD')
			val = this.$moment(val).format('YYMMDD') < endDate && this.$moment(val).format('YYMMDD') > start
			return val
		},
		alertCouselor(val) {
			let start = this.$moment(this.alertStartpicker.date).format('YYMMDD')
			let startDate = new Date(this.alertStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 90))
			let endDate = this.$moment(end).format('YYMMDD')
			val = this.$moment(val).format('YYMMDD') < endDate && this.$moment(val).format('YYMMDD') > start
			return val
		},
		reportCouselor(val) {
			let start = this.$moment(this.reportStartpicker.date).format('YYMMDD')
			let startDate = new Date(this.reportStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 90))
			let endDate = this.$moment(end).format('YYMMDD')
			val = this.$moment(val).format('YYMMDD') < endDate && this.$moment(val).format('YYMMDD') > start
			return val
		},
		endDate() {
			this.endTimepicker.errorMessage = ''
			this.endTimepicker.menu = false
		},
		workDate() {
			this.workStartpicker.menu = false
			this.workEndpicker.menu = false
			this.workDatatable.items = this.workList.filter(
				x => new Date(x.deadline) >= new Date(this.workStartpicker.date) && new Date(x.deadline) <= new Date(this.workEndpicker.date),
			)
		},
		alertDate() {
			this.alertStartpicker.menu = false
			this.alertEndpicker.menu = false
			if (this.$store.state.myData.cls === '1') {
				this.vacations()
			}
		},
		reportDate(tab) {
			this.reportEndpicker.menu = false
			if (tab === 0) {
				this.selfReports()
			} else if (tab === 1) {
				this.reports()
			} else if (tab === 2) {
				const index = this.teamItems.title.indexOf(this.teamItem)
				this.teamReports(this.teamItems.id[index])
			}
		},
		reportDrawerClose() {
			this.reportTab = 0
			this.reportDrawer = !this.reportDrawer
		},
		chatDrawerClose() {
			this.tab = 0
			this.chatNavWidth = 300
			this.chatDrawer = !this.chatDrawer
		},
		disconnectSocket() {
			this.tab = 0
			this.chatNavWidth = 300
			this.subTitle = 'chat List'
		},
		previous() {
			this.tab = 0
			this.chatNavWidth = 300
			this.subTitle = 'chat List'
		},
		team(id) {
			const data = { id: id }
			this.$store
				.dispatch('team', data)
				.then(res => {
					if (res.team.chat) {
						this.chats = res.team.chat
					}
					this.$socket.emit('chatting', {
						chats: res.team.chat,
					})
				})
				.catch(err => {
					console.log({ err })
				})
		},
		calendar(id) {
			const data = { id: id }
			this.$store
				.dispatch('calendar', data)
				.then(res => {
					if (res.calendar.chat) {
						this.chats = res.calendar.chat
					}
					this.$socket.emit('chatting', {
						chats: res.calendar.chat,
					})
				})
				.catch(err => {
					console.log({ err })
				})
		},
		chattingSocket(interID, type, id) {
			this.$socket.emit('join', { interID: interID, type: type, id: id })
			this.$socket.on('chattingList', data => {
				this.chats = data
			})
		},
		multifileUpload(files) {
			this.$socket.emit('join', { interID: this.chatting.interID, type: this.chatting.type, id: this.chatting.id })
			const data = {
				files: files,
			}
			this.$store
				.dispatch('multifileUpload', data)
				.then(res => {
					if (res.multipleUpload.length > 0) {
						const images = []
						res.multipleUpload.forEach((element, index) => {
							images.push(this.server + element.url)
							if (index === res.multipleUpload.length - 1) {
								let emitDatas
								if (this.chats.length > 0) {
									emitDatas = {
										chat: {
											created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
											content: '',
											images: images,
											show: true,
											user: this.$store.state.myData.Name,
											email: this.$store.state.myData.email,
										},
										chats: this.chats,
									}
								} else {
									emitDatas = {
										chat: {
											created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
											content: '',
											images: images,
											show: true,
											user: this.$store.state.myData.Name,
											email: this.$store.state.myData.email,
										},
									}
								}
								this.$socket.emit('chatting', emitDatas)
								this.chats.push({
									created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
									content: '',
									images: images,
									show: true,
									user: this.$store.state.myData.Name,
									email: this.$store.state.myData.email,
								})
								this.$socket.emit('chatupdate', this.chats)
							}
						})
					}
					this.$store.state.fileUploadProgress = 0
				})
				.catch(err => {
					console.log({ err })
				})
		},
		sendChat(e) {
			this.$socket.emit('join', { interID: this.chatting.interID, type: this.chatting.type, id: this.chatting.id })
			if (e.keyCode === 13) {
				if (e.shiftKey) {
					if (this.chat !== '') {
						let emitDatas
						if (this.chats.length > 0) {
							emitDatas = {
								chat: {
									created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
									content: this.chat,
									images: [],
									show: true,
									user: this.$store.state.myData.Name,
									email: this.$store.state.myData.email,
								},
								chats: this.chats,
							}
						} else {
							emitDatas = {
								chat: {
									created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
									content: this.chat,
									images: [],
									show: true,
									user: this.$store.state.myData.Name,
									email: this.$store.state.myData.email,
								},
							}
						}
						this.$socket.emit('chatting', emitDatas)
						this.chats.push({
							created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
							content: this.chat,
							images: [],
							show: true,
							user: this.$store.state.myData.Name,
							email: this.$store.state.myData.email,
						})
						this.$socket.emit('chatupdate', this.chats)
					}
					setTimeout(() => {
						this.chat = ''
					}, 50)
				}
			} else if (e.type === 'click') {
				if (this.chat !== '') {
					this.$socket.emit('chatting', {
						chat: {
							created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
							content: this.chat,
							images: [],
							show: true,
							user: this.$store.state.myData.Name,
							email: this.$store.state.myData.email,
						},
					})
					this.chats.push({
						created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
						content: this.chat,
						images: [],
						show: true,
						user: this.$store.state.myData.Name,
						email: this.$store.state.myData.email,
					})
					this.$socket.emit('chatupdate', this.chats)
				}
				setTimeout(() => {
					this.chat = ''
				}, 50)
			}
		},
		fileupload(e) {
			this.multifileUpload(e.target.files)
		},
		fileClick() {
			document.getElementById('file').click()
		},
		chatApply(data) {
			this.chatNavWidth = 800
			this.tab = 1
			this.workTab = 0
			this.subTitle = data.title
			this.chatting = data
			this.chats = []
			this.chat = ''
			this.chatType = data.type
			if (data.type === 'project') {
				this.calendar(data.id)
			} else if (data.type === 'team') {
				this.team(data.id)
			}
			this.chattingSocket(data.interID, data.type, data.id)
		},
		chatTypeChange(val) {
			this.filterType = val
		},
		me() {
			this.$store
				.dispatch('me')
				.then(res => {
					this.systems()
					if (res.cls === '1') {
						this.$store.state.navList = [
							{ name: 'DashBoard', icon: 'mdi-monitor-dashboard', to: 'DashBoard' },
							{ name: 'WorkList', icon: 'mdi-account-supervisor-circle', to: 'WorkList' },
							{ name: 'CodeReview', icon: 'mdi-application-brackets', to: 'CodeReview' },
							{ name: 'Project', icon: 'mdi-projector-screen-outline', to: 'Project' },
							{ name: 'Vacation', icon: 'mdi-calendar-account', to: 'Vacation' },
							{ name: 'Operating', icon: 'mdi-cog', to: 'Operating' },
							{ name: 'QRenter', icon: 'mdi-qrcode-scan', to: 'QRenter' },
							{ name: 'Logout', icon: 'mdi-logout', to: 'login' },
						]
					} else {
						this.$store.state.navList = [
							{ name: 'DashBoard', icon: 'mdi-monitor-dashboard', to: 'DashBoard' },
							{ name: 'WorkList', icon: 'mdi-account-supervisor-circle', to: 'WorkList' },
							{ name: 'CodeReview', icon: 'mdi-application-brackets', to: 'CodeReview' },
							{ name: 'Project', icon: 'mdi-projector-screen-outline', to: 'Project' },
							{ name: 'Vacation', icon: 'mdi-calendar-account', to: 'Vacation' },
							{ name: 'QRenter', icon: 'mdi-qrcode-scan', to: 'QRenter' },
							{ name: 'Logout', icon: 'mdi-logout', to: 'login' },
						]
					}
					if (res.teams.length > 0) {
						res.teams.forEach(element => {
							element.calendars.forEach(calendar => {
								this.chatList.push({
									id: calendar.id,
									type: 'project',
									title: calendar.name,
									member: element.users_permissions_users,
									interID: calendar.interID,
								})
							})
							this.chatList.push({
								id: element.id,
								type: 'team',
								title: element.title,
								member: element.users_permissions_users,
								interID: element.interID,
							})
						})
					}
					if (res.teams.length > 0) {
						this.teamReportsCount(res.teams[0].title)
					}
					if (this.$store.state.myData.cls === '1') {
						this.vacationsCount()
					}
					this.selfReports()
					this.reportsCount()
				})
				.catch(err => {
					console.log({ err })
				})
		},
		searchPeriodCalculation() {
			let date = new Date()
			let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
			let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
			let weekObj = null
			let weekObjArray = new Array()
			let weekStand = 8
			let firstWeekEndDate = true
			let thisMonthFirstWeek = firstDay.getDay()
			for (var num = 1; num <= 6; num++) {
				if (lastDay.getMonth() != firstDay.getMonth()) {
					break
				}
				weekObj = new Object()
				if (firstDay.getDay() <= 1) {
					if (firstDay.getDay() == 0) {
						firstDay.setDate(firstDay.getDate() + 1)
					}
					weekObj.weekStartDate =
						firstDay.getFullYear().toString() +
						'-' +
						this.numberPad((firstDay.getMonth() + 1).toString(), 2) +
						'-' +
						this.numberPad(firstDay.getDate().toString(), 2)
				}
				if (weekStand > thisMonthFirstWeek) {
					if (firstWeekEndDate) {
						if (weekStand - firstDay.getDay() == 1) {
							firstDay.setDate(firstDay.getDate() + (weekStand - firstDay.getDay()) - 1)
						}

						if (weekStand - firstDay.getDay() > 1) {
							firstDay.setDate(firstDay.getDate() + (weekStand - firstDay.getDay()) - 1)
						}

						firstWeekEndDate = false
					} else {
						firstDay.setDate(firstDay.getDate() + 6)
					}
				} else {
					firstDay.setDate(firstDay.getDate() + (6 - firstDay.getDay()) + weekStand)
				}
				if (typeof weekObj.weekStartDate !== 'undefined') {
					weekObj.weekEndDate =
						firstDay.getFullYear().toString() +
						'-' +
						this.numberPad((firstDay.getMonth() + 1).toString(), 2) +
						'-' +
						this.numberPad(firstDay.getDate().toString(), 2)
					weekObjArray.push(weekObj)
				}
				firstDay.setDate(firstDay.getDate() + 1)
			}
			this.weekObjArray = weekObjArray
		},
		numberPad(num, width) {
			num = String(num)
			return num.length >= width ? num : new Array(width - num.length + 1).join('0') + num
		},
	},
	created() {
		this.$socket.on('connect', data => {
			setTimeout(() => {
				this.$socket.emit('join', { interID: this.chatting.interID, type: this.chatting.type, id: this.chatting.id })
			}, 1000)
			console.log(data + ' - connect')
		})
		this.$socket.on('connect_error', function(data) {
			console.log(data + ' - connect_error')
		})
		this.$socket.on('disconnect', function(data) {
			console.log(data + ' - disconnect')
		})
		this.searchPeriodCalculation()
		this.workStartpicker.date = this.$moment(new Date())
			.subtract(14, 'days')
			.format('YYYY-MM-DD')
		this.workEndpicker.date = this.$moment(new Date())
			.add(14, 'days')
			.format('YYYY-MM-DD')
		this.alertStartpicker.date = this.$moment(new Date())
			.subtract(45, 'days')
			.format('YYYY-MM-DD')
		this.alertEndpicker.date = this.$moment(new Date())
			.add(45, 'days')
			.format('YYYY-MM-DD')
		this.reportStartpicker.date = this.$moment(new Date())
			.subtract(45, 'days')
			.format('YYYY-MM-DD')
		this.reportEndpicker.date = this.$moment(new Date())
			.add(45, 'days')
			.format('YYYY-MM-DD')
		this.me()
	},
}
</script>
<style lang="scss">
.img-viewer {
	img {
		width: 33.3%;
		background-size: contain;
	}
	&.my {
		text-align: right;
	}
}
.chattingCard {
	overflow: auto;
	height: 70vh;
}
.chat-layout {
	padding: 0px 10px;
	margin-bottom: 12px;
	.name-date {
		font-size: 8px;
		font-weight: bold;
		margin-bottom: 4px;
		&.my {
			text-align: right;
		}
	}
	.content {
		line-break: anywhere;
		white-space: pre-line;
		background-color: white;
		padding: 10px 16px;
		border-radius: 15px 10px 10px 0px;
		box-shadow: 0px 3px 1px -2px rgb(236 240 244 / 20%), 0px 2px 2px 0px rgb(236 240 244 / 14%), 0px 1px 5px 0px rgb(236 240 244 / 12%);
		font-weight: 500;
		color: black;
		font-size: 18px;
		width: fit-content;
	}
	.myContent {
		line-break: anywhere;
		text-align: left;
		white-space: pre-line;
		padding: 10px 16px;
		border-radius: 10px 15px 0px 10px;
		box-shadow: 0px 3px 1px -2px rgb(236 240 244 / 20%), 0px 2px 2px 0px rgb(236 240 244 / 14%), 0px 1px 5px 0px rgb(236 240 244 / 12%);
		font-weight: 500;
		color: white;
		font-size: 18px;
		width: fit-content;
	}
}
.main-layout {
	height: 85vh;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	padding: 0 16px 48px 16px;
}
.tab-color {
	background-color: #4c428c !important;
}
.close-btn {
	justify-content: center;
	position: absolute;
	right: 13px;
	top: 13px;
	background-color: #ffffff;
}
</style>
